
import React from 'react'
import PropTypes from 'prop-types'
import { StaticQuery, graphql } from 'gatsby'
import Image from 'gatsby-image'
import { rhythm } from '../utils/typography'
import style from './bio.module.scss'

const bioQuery = graphql`
query BioQuery {
  avatar: file(absolutePath: { regex: "/profile.jpg/" }) {
    childImageSharp {
      fixed(width: 50, height: 50, quality: 90) {
        ...GatsbyImageSharpFixed
      }
    }
  }
  avatarBig: file(absolutePath: { regex: "/profile.jpg/" }) {
    childImageSharp {
      fixed(width: 150, height: 150, quality: 90) {
        ...GatsbyImageSharpFixed
      }
    }
  }
  site {
    siteMetadata {
      author
      social {
        twitter
        github
      }
    }
  }
}
`

const bioContent = (data, big, className) => {
  const { author, social } = data.site.siteMetadata
  const avatar = big ? data.avatarBig.childImageSharp.fixed : data.avatar.childImageSharp.fixed;

  return (
    <div className={`${style.root} ${className}`} big={big.toString()}>
      <Image
        fixed={avatar}
        alt={author}
        className={big ? style.imgBig : style.img}
        style={{ marginRight: rhythm(1 / 2) }}
        imgStyle={{ transform: `scale(1.15)`, }}
      />
      <p>
        My name is <strong>{author}</strong> and welcome to my blog. You can also follow me on
        {` `}
        <a href={`https://twitter.com/${social.twitter}`} target="_blank" rel="noopener noreferrer">
          Twitter
        </a>
        {` `}
        and/or
        {` `}
        <a href={`https://github.com/${social.github}`} target="_blank" rel="noopener noreferrer">
          Github
        </a>.
      </p>
    </div>
  )
}

const Bio = ({big, className}) => (<StaticQuery query={bioQuery} render={(data) => bioContent(data, big, className)}/>)

Bio.defaultProps = {
  big: false,
  className: '',
}

Bio.propTypes = {
  big: PropTypes.bool,
  className: PropTypes.string,
}

export default Bio;