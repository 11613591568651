import React from 'react';
import PropTypes from 'prop-types';
import style from './lang-selector.module.scss';

const langsName = {
  es: 'Español',
  en: 'English',
}

const langsEmoji = {
  es: '🇦🇷',
  en: '🇺🇸'
}

const text = {
  es: 'Otros idiomas:',
  en: 'Other languages:'
}

const LangSelector = ({className, current, langs, onSelect}) => {
  return (<ul className={`${className} ${style.root}`}>
    <li>{text[current]}</li>
    { langs.map(l => <li role="link" onClick={() => onSelect(l)} onKeyPress={() => onSelect(l)} key={l}>{langsName[l]} <i>{langsEmoji[l]}</i></li>) }
  </ul>)
}

LangSelector.propTypes = {
  langs: PropTypes.arrayOf(PropTypes.string.isRequired).isRequired,
  current: PropTypes.string.isRequired,
  onSelect: PropTypes.func.isRequired
}

export default LangSelector