import React from 'react'
import { Link, graphql } from 'gatsby'
import Helmet from 'react-helmet';
import pluralize from 'pluralize';
import format from 'date-fns/format'

import Bio from '../components/bio'
import Layout from '../components/layout'
import SEO from '../components/seo'
import LangSelector from '../components/lang-selector'
import { rhythm, scale } from '../utils/typography'
import style from './blog-post.module.scss'
import 'prismjs/themes/prism-tomorrow.css'

export const pageQuery = graphql`
  query BlogPostBySlug($uniqueSlug: String!) {
    site {
      siteMetadata {
        title
        author
        keywords
      }
    }
    allMarkdownRemark(filter: { fields: { uniqueSlug: { eq: $uniqueSlug } } }) {
      edges {
        node {
          id
          excerpt(pruneLength: 160)
          html
          timeToRead
          fields {
            slug
          }
          frontmatter {
            lang
            title
            date
            keywords
            draft
            styles
          }
        }
      }

    }
  }
`

class BlogPostTemplate extends React.Component {
  render() {
    const posts = this.props.data.allMarkdownRemark.edges
    const hasLanguages = posts.length > 1;
    const post = posts.find(p => p.node.fields.slug === this.props.pageContext.slug).node
    const others = posts.filter(p => p.node !== post).map(p => p.node)
    const {title: siteTitle, keywords: globalKeywords} = this.props.data.site.siteMetadata
    const { previous, next } = this.props.pageContext

    const onLanguageChange = (langCode) => {
      const langPost = others.find(p => p.frontmatter.lang === langCode)
      if (langPost) {
        window.location.href = langPost.fields.slug;
      }
    }

    return (
      <Layout location={this.props.location} title={siteTitle} className={style.root} showMenu={false} smallTitle loadHackFont>
        <Helmet>
          {(post.frontmatter.styles || []).map(link => <link rel="stylesheet" href={link} />)}
        </Helmet>
        <SEO lang={post.frontmatter.lang} title={post.frontmatter.title} description={post.excerpt} keywords={globalKeywords.concat(post.frontmatter.keywords)} />
        <div>
        <h1>{post.frontmatter.title} {post.frontmatter.draft ? '(DRAFT)' : ''}</h1>
        <p
          style={{
            ...scale(-1 / 5),
            display: `block`,
            marginBottom: rhythm(1),
            marginTop: rhythm(-1),
          }}
        >
          {format(new Date(post.frontmatter.date), 'MMMM dd, yyyy')} - {post.timeToRead} {pluralize('minute', post.timeToRead)} read
        </p>
        { hasLanguages ? <LangSelector current={post.frontmatter.lang} onSelect={onLanguageChange} langs={others.map(p => p.frontmatter.lang)} /> : null }
        <div dangerouslySetInnerHTML={{ __html: post.html }} />
        <hr
          style={{
            marginBottom: rhythm(1),
          }}
        />
        <Bio />

        <ul
          style={{
            display: `flex`,
            flexWrap: `wrap`,
            justifyContent: `space-between`,
            listStyle: `none`,
            padding: 0,
          }}
        >
          <li>
            {previous && (
              <Link to={previous.fields.slug} rel="prev">
                ← {previous.frontmatter.title}
              </Link>
            )}
          </li>
          <li>
            {next && (
              <Link to={next.fields.slug} rel="next">
                {next.frontmatter.title} →
              </Link>
            )}
          </li>
        </ul>
        </div>
      </Layout>
    )
  }
}

export default BlogPostTemplate